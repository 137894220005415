<template>
  <div>
    <component
      :is="fieldProps.is"
      v-bind="omit(fieldProps, 'is')"
      @change="emit('value:change', { fieldName: props.name, event: $event })"
    />
  </div>
</template>

<script setup lang="ts">
import { regexPercentageRegex } from "@/helpers/constants";
import { getValueForDateField } from "@/helpers/formatting";
import type { IOffer } from "@/models/funders";
import omit from "lodash/omit";
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const FIELD_TYPES = {
  ["date"]: ["date"],
  ["number"]: ["integer", "int", "number"]
};

const CURRENCIES_MAP: Record<string, unknown> = {
  USD: {
    currency: "USD",
    distractionFree: false,
    useGrouping: true,
    precision: 2,
    valueRange: { min: 0, max: 999999999 }
  },
  CAD: {
    currency: "CAD",
    distractionFree: false,
    useGrouping: true,
    precision: 2,
    valueRange: { min: 0, max: 999999999 }
  }
};

const props = defineProps({
  value: {
    type: [String, Number],
    required: false
  },
  name: {
    type: String as PropType<keyof IOffer>,
    required: true
  },
  section: {
    type: String,
    default: ""
  },
  productTypeId: {
    type: Number,
    required: true
  },
  rules: {
    type: String,
    required: true
  },
  startDate: {
    type: String,
    default: ""
  },
  currency: {
    type: String,
    default: "USD"
  },
  calculatedTerm: {
    type: [Number, String],
    default: 0
  },
  calculatedCommission: {
    type: [Number, String],
    required: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  initialPayment: {
    type: [Number, String],
    default: 0
  }
});

const emit = defineEmits<{
  "value:change": [{ fieldName: keyof IOffer; event: Event }];
}>();

const { getters } = useStore();
const { t } = useI18n();

const fieldProps = computed(() => {
  const { name, value, disabled, rules, currency } = props;

  if (
    [
      "interest_charged",
      "currency",
      "base_rate",
      "term_length_timeframe",
      "time_to_funding_timeframe"
    ].includes(name)
  ) {
    const options = getFieldOptions(name);
    return {
      class: "mb-5",
      is: "lf-dropdown",
      name,
      options,
      value,
      modelValue: value,
      placeholder: formatProductFieldTitle(name),
      disabled
    };
  }

  return {
    class: { "h-12": name === "term_length" },
    is: getComponentType(name, rules),
    name: name,
    type: getFieldType(rules, name),
    value: getValue(name),
    modelValue: rules.includes("numeric") ? value : null,
    placeholder: formatProductFieldTitle(name),
    min: getMinAttributeValue(),
    max: getMaximumValue(name),
    maxlength: getMaxInputLength(name),
    disabled: disabled,
    ...(getComponentType(name, rules) === "lf-currency" && {
      options: CURRENCIES_MAP[currency]
    })
  };
});

const getFieldOptions = (fieldName: string) =>
  ({
    currency: { USD: "USD", CAD: "CAD" },
    interest_charged: getters["options/interestChargedTimeframe"],
    base_rate: getters["options/baseRate"],
    term_length_timeframe: getters["options/termLengthTimeframe"],
    time_to_funding_timeframe: getters["options/timeToFindingTimeframe"]
  })[fieldName];

const getMaximumValue = (name: string) =>
  !["max_sell_factor_rate", "min_buy_factor_rate"].includes(name) &&
  name.includes("rate")
    ? 99.99
    : null;

const formatProductFieldTitle = (name: string) => {
  if (name === "total_initial_payment") {
    return t("FUNDERS.TOTAL_INITIAL_PAYMENT_FIELD_NAME");
  }

  if (name === "base_rate") {
    return t("OFFERS.COLUMNS.BASE_RATE");
  }

  const options = getters["options/productFieldsDictionary"](
    props.productTypeId
  ) as Record<string, Record<string, string>>;

  const fieldName = options[props.section][props.name];
  return fieldName || t(`FIELD_DICTIONARY.${props.name}`);
};

const getFieldType = (rules: string, name: string) => {
  if (["offer_generated_date"].includes(name)) {
    return "text";
  }
  const rulesArray = rules.split("|");
  return (
    Object.keys(FIELD_TYPES).find((key) =>
      rulesArray.some((rule) =>
        FIELD_TYPES[key as keyof typeof FIELD_TYPES].includes(rule)
      )
    ) ?? "text"
  );
};

const getComponentType = (name: string, rules: string) => {
  if (
    [
      "erc_credit_amount",
      "erc_total_commission",
      "number_of_payments",
      "time_to_funding",
      "apr"
    ].includes(name)
  ) {
    return "lf-input";
  }

  if (
    !props.name.includes("term") &&
    rules.includes("numeric") &&
    (!regexPercentageRegex.test(name) || props.name.includes("interest_amount"))
  ) {
    return "lf-currency";
  }
  if (name.includes("payment_frequency")) {
    return "payment-frequency-dropdown";
  }
  if (["offer_generated_date"].includes(name)) {
    return "date-time-input";
  }
  return "lf-input";
};

const getMaxInputLength = (name: keyof IOffer) => {
  if (name === "term_length") {
    return 3;
  }
  if (name === "number_of_payments") {
    return 7;
  }
  return null;
};

const getValue = (name: string) => {
  if (name === "total_initial_payment") {
    const prefix = "US$";
    const value = Number(props.initialPayment).toFixed(2);
    return `${prefix}${value}`;
  }

  if (
    [
      "expires_at",
      "offer_generated_date",
      "offer_expiration_date",
      "start_date",
      "funded_date"
    ].includes(name)
  ) {
    const withTime = ["offer_generated_date"].includes(name);
    return props.value ? getValueForDateField(`${props.value}`, withTime) : "";
  }

  if (name === "term_length") {
    return props.calculatedTerm;
  }

  if (["funding_commission", "funding_term_loan_commission"].includes(name)) {
    const parsedCommission = props.calculatedCommission
      ? parseFloat(`${props.calculatedCommission}`)
      : NaN;

    if (Number.isNaN(parsedCommission)) {
      return "0.00";
    }
    return parsedCommission.toFixed(2);
  }

  return props.value;
};

const getMinAttributeValue = () => {
  if (props.name === "end_date") {
    return props.startDate || "";
  }

  if (["term", "term_length"].includes(props.name)) {
    return 1;
  }
  return "";
};
</script>
